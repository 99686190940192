import React from "react";
import Services from "./services";
import Parser from 'html-react-parser';

const index = () => {
  const { data, Environtment } = Services();
  return (
    <section className="reveal flex flex-col justify-center items-center p-8">
      <div className="py-4 mb-4 w-[20%] sm:w-[60%] border-b-2 text-center border-red-500">
        <span className="text-[32px] font-light">Value Added Service</span>
      </div>
      <div className="flex flex-wrap items-stretch w-full slide-in-content left">
        {data?.map((item) => (
          <div className="w-1/3 sm:w-full p-4 flex flex-col items-center aspect-square">
            <img
              src={`${Environtment.REACT_APP_SOURCE_URL}/${item?.image}`}
              alt="..."
              className="w-[4rem] mb-3"
            />
            <span className="text-[1rem] font-bold mb-3">
              {item?.title}
            </span>
            <p className="text-center">
              {Parser(item?.description)}
            </p>
          </div>
        ))}
        {/* <div className="w-1/3 slide-in-content right sm:w-full p-4 flex flex-col items-center">
            <img
              src="/image/value-service/verified-account.png"
              alt="..."
              className="w-[4rem] mb-3"
            />
            <span className="text-[1rem] font-bold mb-3">
              Bank account verification
            </span>
            <p className="text-center">
              Bank account verification ensures funds are going to and coming
              from legitimate bank accounts and ensures a pleasant user
              experience. Most importantly can help mitigate the risks of
              unsuccessful transactions and potential fraudsters.
            </p>
          </div>
          <div className="w-1/3 p-4 sm:w-full flex flex-col items-center">
            <img
              src="/image/value-service/analyst.png"
              alt="..."
              className="w-[4rem] mb-3"
            />
            <span className="text-[1rem] font-bold mb-3">Data Analysis</span>
            <p className="text-center">
              Our system track and record all of your transactions, making your
              history of transactions and purchases available to be accessed.
              This will help to make an impactful decision and optimize your
              resources to get the maximum benefit.
            </p>
          </div> */}
      </div>
    </section>
  );
};

export default index;
