import React from "react";
import {DataFooter} from "constant";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import {AiOutlineClockCircle} from 'react-icons/ai';
import {BsWhatsapp, BsTelegram} from 'react-icons/bs';

const index = () => {
  return (
    <footer className="flex flex-col bg-dark-blue text-white mt-auto h-full">
      <div className="flex sm:flex-col p-8 items-stretch">
        <div className="w-1/3 sm:w-full flex-col flex p-4">
          <span className="text-[18px] mb-4 font-bold">
            {DataFooter.contact.company}
          </span>
          <div className="flex items-center text-[14px] font-normal mb-1">
            <span>{DataFooter.contact.address}</span>
          </div>
          <div className="flex items-center text-[14px] font-normal">
            <span>{DataFooter.contact.phone}</span>
          </div>
        </div>
        <div className="w-1/4 sm:w-full flex flex-col items-start justify-start p-4">
          <span className="text-[18px] mb-4 font-bold">CONTACT US</span>
          <a href="https://t.me/Admmekarsari" className="flex flex-row items-center my-1 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110">
            <BsTelegram className="mr-3"/>
            <span>Telegram</span>
          </a>
          <a href="https://bit.ly/3Twubhj" className="flex flex-row items-center my-1 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110">
            <BsWhatsapp className="mr-3"/>
            <span>Whatsapp</span>
          </a>
        </div>
        <div className="w-1/4 sm:w-full flex flex-col items-start justify-start p-4">
          <span className="text-[18px] mb-4 font-bold">OUR OFFICE HOURS</span>
          <div className="flex flex-row items-center my-1">
            <AiOutlineClockCircle className="mr-3"/>
            <span>Mon - Sun: 24 Hours</span>
          </div>
        </div>
        <div className="w-1/5 sm:w-full flex flex-col items-end justify-center">
          <a
            href="https://play.google.com/store/apps/details?id=id.co.aviana.solution3m&gl=US"
            className="border flex items-center w-fit border-white rounded-full p-4 hover:bg-white/50"
          >
            <span className="mr-2 text-xs">DOWNLOAD MID PAY</span>
            <IoLogoGooglePlaystore className="text-current text-[32px]" />
          </a>
        </div>
      </div>
      <div className="w-full border border-black py-2 px-10">
        <span className="text-[12px]">© Copyright - ABMC 2019</span>
      </div>
    </footer>
  );
};

export default index;
