import {Banner} from 'components';
const index = () => {
  return (
    <div className="w-full aspect-video">
        {/*Banner */}
        <Banner
          classes="bg-no-repeat bg-blue-500 bg-fixed bg-[#606c9f] bg-cover bg-left h-full w-full"
          imgUrl="/image/wall-abm.png"
        >
          <div className="w-1/2 h-full bg-dark-blue/80 flex items-center px-12">
            <img src="/image/logo.png" className="w-full" alt="..." />
          </div>
        </Banner>
      </div>
  )
}

export default index;