import axios from "axios";
import { useEffect, useState } from "react";
import { Environtment } from "constant";
const Services = () => {
  const [aboutUs, setAboutUs] = useState([]);

  const getAboutUs = () => {
    axios?.get(`${Environtment.REACT_APP_API_URL}/about-us`).then((res) => {
      if(res?.status === 200){
        setAboutUs(res?.data?.data);
      }
    }).catch((err) => {
      console.log('err', err);
    })
  }

  useEffect(() => {
    getAboutUs();
  }, []);

  return {
    aboutUs
  }
}

export default Services;