import PropTypes from "prop-types";
import { Menu } from "components";
import { DataMenu } from "constant";
import React from "react";
import Services from "./services";

const index = (props) => {
  const { logo, classesLogo } = props;
  const { open, setOpen } = Services(props);
  const pathname = window.location.pathname;

  return (
    <nav id="navbar" className="navbar-container">
      <div className="navbar-content">
        <div className="w-[20%]">
          {logo && (
            <a href="/#">
              <img src={logo} alt="..." className={classesLogo} />
            </a>
          )}
        </div>
        <div className="flex items-center m-0 justify-between lg:justify-end h-auto w-[80%]">
          <div
            className="items-center bg-transparent p-2 mx-5 rounded-lg 
            hidden lg:block"
            onClick={() => setOpen(!open)}
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div
            className={`flex h-full justify-between w-full lg:fixed lg:top-0 lg:left-0 lg:z-50 lg:bg-white ${
              open
                ? "lg:flex lg:h-fit lg:border transition delay-150 duration-500 ease-in-out opacity-100"
                : "lg:hidden"
            } lg:w-full order-1`}
            id="mobile-menu-4"
          >
            <div
              className={`${
                open && "h-fit"
              } flex flex-wrap w-full justify-between lg:flex-col lg:mt-10 h-auto lg:w-full flex-row mt-0`}
            >
              <div
                className={`absolute top-0 right-0 bg-transparent p-2 m-2 rounded-lg 
            hidden lg:${open && "block"}`}
                onClick={() => setOpen(!open)}
              >
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              {DataMenu.map((item, index) =>
                item.children.length < 1 ? (
                  <menu
                    style={{ flex: "1 1 0" }}
                    key={index}
                    className={`h-full px-1 my-0 py-0`}
                  >
                    <Menu
                      link={item.link}
                      label={item.menu}
                      classes={`flex justify-center transition delay-150 m-0
duration-300 text-[1rem] cursor-pointer w-full h-full items-center p-2 hover:bg-dark-blue hover:text-white font-normal ${
                        pathname === item.link
                          ? "font-medium border-b-2 border-dark-blue bg-transparent"
                          : "bg-transparent border-transparent"
                      }`}
                    />
                  </menu>
                ) : (
                  <menu
                    style={{ flex: "1 1 0" }}
                    key={index}
                    className={`h-auto px-1`}
                  >
                    <Menu
                      link={item.link}
                      label={item.menu}
                      classes={`flex justify-center transition delay-150 m-0
duration-300 text-[1rem] cursor-pointer w-full h-full items-center p-2 hover:border-dark-blue hover:border-b-2 font-normal ${
                        pathname === item.link
                          ? "font-medium border-b-2 border-dark-blue bg-transparent"
                          : "bg-transparent border-transparent"
                      }`}
                    />
                  </menu>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

index.propTypes = {
  containerClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  logo: PropTypes.string,
  classesLogo: PropTypes.string,
};

export default index;
