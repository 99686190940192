import React, { useEffect } from "react";
import { 
  Templates, 
  SectionTop, 
  SectionAboutUs, 
  SectionOurServices, 
  SectionValueAddedServices,
  SectionUseCase,
  SectionWhyShouldPartner,
  SectionPartner,
  SectionNews,
} from "components";
import { BsTelegram, BsWhatsapp } from "react-icons/bs";

export default function App() {
  function slideIn() {
    let slideIns = document.querySelectorAll(".slide-in-content");
    for (var i = 0; i < slideIns.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = slideIns[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        slideIns[i].classList.add("active");
      } else {
        slideIns[i].classList.remove("active");
      }
    }
  }
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      }
    }
  }

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", reveal);
    window.addEventListener("scroll", slideIn);
  }

  return (
    <Templates>
      <SectionTop/>
      <SectionAboutUs/>
      <SectionOurServices/>
      <SectionValueAddedServices/>
      <SectionUseCase/>
      <SectionWhyShouldPartner/>
      <SectionPartner/>
      <SectionNews/>
      <div className="fixed left-0 m-4 inset-y-1/3 flex-col">
        <a
          href="https://bit.ly/3Twubhj"
          className="w-fit h-fit flex items-center justify-center rounded-full shadow-full bg-white text-green-light border-2 border-green-light p-4 mb-4 hover:text-white hover:bg-green-light"
        >
          <BsWhatsapp size={24} />
        </a>
        <a
          href="https://t.me/Admmekarsari"
          className="w-fit h-fit flex items-center justify-center rounded-full shadow-full bg-white p-4 text-dark-blue border-2 border-dark-blue hover:text-white hover:bg-dark-blue"
        >
          <BsTelegram size={24} />
        </a>
      </div>
    </Templates>
  );
}
