import axios from "axios";
import { DataUseCase, Environtment } from "constant";
import { useEffect, useState } from "react";
const Services = () => {
  const [data, setData] = useState([]);
  const getData = () => {
    axios.get(`${Environtment.REACT_APP_API_URL}/use-case`).then((res) => {
      if(res?.status === 200){
        setData(res?.data?.data);
      }
    }).catch((err) => {
      console.log('err', err);
    })
  }

  useEffect(() => {
    getData();
  }, []);

  return {
    DataUseCase,
    data,
    Environtment,
  }
}

export default Services;