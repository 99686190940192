import { useState } from "react";
const Services = () => {
  const [open, setOpen] = useState(false);

  return {
    open,
    setOpen
  }
}

export default Services