import React from "react";
import PropTypes from 'prop-types';

const index = (props) => {
  const {
    classes,
    label,
    labelClass,
  } = props;
  return (
    <div className={classes}>
      {label && (
        <span className={labelClass}>{label}</span>
      )}
    </div>
  )
}

index.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.string,
  labelClass: PropTypes.string,
}

export default index;