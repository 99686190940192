import PropTypes from 'prop-types';
import React from 'react';

const Input = (props) => {
  const {classesInput, classes, placeHolder, handler, type, required, value, onFocus, onBlur} = props;
  return (
    <div className={classes || ''}>
      <input
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        type={type}
        className={classesInput || ''}
        placeholder={placeHolder}
        required={required}
        onChange={(e) => handler(e)}
      />
    </div>
  )
}

Input.propTypes = {
  classes: PropTypes.string,
  classesInput: PropTypes.string,
  placeHolder: PropTypes.string,
  handler: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string
}

Input.defaultProps = {
  classes: '',
  classesInput: '',
  placeHolder: '',
  type: '',
  required: false,
  value: '',
  handler: ()=>{},
  onFocus: ()=>{},
  onBlur: ()=>{},
}
export default Input;