import React from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";
import Services from "./services";
const index = () => {
  const {
    partners,
    Environtment,
  } = Services();
  return (
    <section
        id="news"
        className="reveal flex flex-col justify-center items-center p-8"
      >
        <div className="py-4 mb-4 w-[20%] border-b-2 text-center border-red-500">
          <span className="text-[32px] font-light">Our Partner</span>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          autoplay={{
            duration: 5000,
            pauseOnMouseEnter: false,
            disableOnInteraction: false,
          }}
          loop
          className="w-full h-auto"
          slidesPerView={5}
        >
          {partners?.map((item, index) => (
            <SwiperSlide key={index} className="flex items-center">
              <img src={`${Environtment.REACT_APP_SOURCE_URL}/${item?.image}`} alt="..." className="flex items-center" />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
  )
}

export default index;