import React from "react";
import Services from "./services";

const index = () => {
  const {
    DataUseCase,
    data,
    Environtment
  } = Services();
  console.log('datas', data);
  return (
    <section
        className="reveal flex flex-col justify-center items-center p-8"
      >
        <div className="py-4 mb-4 w-[20%] sm:w-[60%] border-b-2 text-center border-red-500">
          <span className="text-[32px] font-light">Use Case</span>
        </div>
        <div className="flex flex-wrap w-full md:justify-center md:items-center">
          {data?.map((item, index) => (
            <div
              key={index}
              className="w-1/4 md:w-full p-4 flex flex-col slide-in-content left"
            >
              <div className="relative p-3 w-[8rem] border aspect-square rounded-full">
                <img src={`${Environtment.REACT_APP_SOURCE_URL}${item?.image}`} alt="..." className="w-full" />
                <div className="absolute left-0 bottom-0">
                  <span className="bg-red-500 flex items-center opacity-75 animate-pulse justify-center absolute rounded-full text-white bottom-0 -left-4 w-[2rem] h-[2rem]">
                    {index + 1}
                  </span>
                </div>
              </div>
              <p className="text-start mt-2">{item?.title}</p>
            </div>
          ))}
        </div>
      </section>
  )
}

export default index;