import { TitleCard } from "components/atoms";
import Services from "./services";
const index = () => {
  const { DataOurServices, dataPaymentServices, dataBillPayment, dataVerification, Environtment } = Services();
  return (
    <section
      id="services"
      className="reveal flex flex-col justify-center items-center p-8"
    >
      <div className="py-4 mb-4 w-[20%] sm:w-[60%] border-b-2 text-center border-red-500">
        <span className="text-[32px] font-light">Our Services</span>
      </div>
      <div className="flex sm:flex-col flex-wrap w-full">
        <div className="w-1/3 sm:w-auto border flex flex-col py-20 px-4">
          <div className="border-t relative">
            <TitleCard
              label="Payment Services"
              labelClass="text-[28px] font-bold"
              classes="absolute -top-6 bg-white pr-6"
            />
            <div className="flex flex-wrap w-full mt-5">
              <div className="w-1/2 flex flex-col font-light items-center text-center">
                <span className="text-[25px]">Pay In</span>
                <div className="border-top border w-[70%] border-dark-blue" />
                {dataPaymentServices?.map(
                  (item) =>
                    item?.category === "PAY IN" && (
                      <div className="flex flex-col w-full items-center justify-center my-3 slide-in-content top">
                        <div className="w-1/3 aspect-square flex justify-center">
                          <img
                            src={`${Environtment.REACT_APP_SOURCE_URL}${item?.image}`}
                            alt="..."
                            className="aspect-square"
                          />
                        </div>
                        <span>{item?.title}</span>
                      </div>
                    )
                )}
              </div>
              <div className="w-1/2 flex flex-col text-[0.9rem] font-light items-center text-center">
                <span className="text-[25px]">Pay Out</span>
                <div className="border-top border w-[70%] border-dark-blue" />

                {dataPaymentServices?.map(
                  (item) =>
                    item?.category === "PAY OUT" && (
                      <div className="flex flex-col w-full items-center justify-center my-3 slide-in-content top">
                        <div className="w-1/3 aspect-square flex justify-center">
                          <img
                            src={`${Environtment.REACT_APP_SOURCE_URL}${item?.image}`}
                            alt="..."
                            className="aspect-square"
                          />
                        </div>
                        <span>{item?.title}</span>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/3 sm:w-auto border flex flex-col py-20 px-4">
          <div className="border-t relative">
            <TitleCard
              label="Bill Payment"
              labelClass="text-[28px] font-bold"
              classes="absolute -top-6 bg-white pr-6"
            />
            <div className="relative text-[0.9rem] flex flex-wrap mt-8">
              {dataBillPayment?.map((item, index) => (
                <div className="flex flex-col w-1/3 items-center justify-center my-3 slide-in-content top">
                  <div className="w-1/2 aspect-square flex justify-center">
                    <img
                      src={`${Environtment.REACT_APP_SOURCE_URL}${item?.image}`}
                      alt="..."
                      className="aspect-square"
                    />
                  </div>
                  <span>{item?.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-1/3 sm:w-auto h-auto border flex flex-col py-20 px-4">
          <div className="border-t relative h-full">
            <TitleCard
              label="Verification ID"
              labelClass="text-[28px] font-bold indent-[1em]"
              classes="absolute -top-6 bg-white pr-6"
            />
            <div className="relative text-[0.9rem] flex flex-col justify-start h-full items-center flex-wrap mt-12">
              {dataVerification?.map((item, index) => (
                <div className="flex flex-col w-1/3 items-center justify-center my-3 slide-in-content top">
                <div className="w-1/2 aspect-square flex justify-center">
                  <img
                    src={`${Environtment.REACT_APP_SOURCE_URL}${item?.image}`}
                    alt="..."
                    className="aspect-square"
                  />
                </div>
                <span className="text-center">{item?.title}</span>
              </div>
              ))}
              {/* <div className="flex flex-col w-full items-center justify-center slide-in-content top">
                  <img
                    src="/image/verification-id/sim.png"
                    className="w-[5rem]"
                    alt="https://www.flaticon.com/"
                    title="https://www.flaticon.com/"
                  />
                  <span>SIM SWAP</span>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
