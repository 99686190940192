import React from "react";
import Services from "./services";

const index = () => {
  const {aboutUs} = Services();

  console.log('ehek', aboutUs)
  return (
    <section
      id="about-us"
      className="reveal flex flex-col justify-center items-center p-8"
    >
      <div className="py-4 mb-4 w-[20%] sm:w-[60%] border-b-2 text-center border-red-500">
        <span className="text-[32px] font-light">About Us</span>
      </div>
      <div className="text-[20px] text-center font-bold mb-2">
        {aboutUs[0]?.title} - <em>{aboutUs[0]?.sub_title}</em>
      </div>
      <div className="text-center px-5 text-[16px]">
        <p>
          {aboutUs[0]?.description}
        </p>
      </div>
    </section>
  );
};

export default index;
