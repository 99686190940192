import PropTypes from 'prop-types';
import React from 'react';
import Services from './Services';

const index = (props) => {
  const {imgUrl, children, classes} = props;
  const {
    ref
  } = Services();
  return(
    <div
      style={{ backgroundImage: `url(${imgUrl})` }}
      className={classes || `bg-center bg-cover bg-no-repeat h-full w-full aspect-h-6 aspect-w-16 tablet:aspect-h-11`}
    >
      <div ref={ref} className="h-full w-full">
        {children}
      </div>
    </div>
  );
}

index.propTypes = {
  imgUrl: PropTypes.string,
  children: PropTypes.elementType,
  classes: PropTypes.string,
}

export default index;