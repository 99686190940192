import { DataPartner, Environtment} from "constant";
import axios from "axios";
import { useEffect, useState } from "react";

const Services = () => {
  const [partners, setParners] = useState([]);
  const getPartners = () => {
    axios.get(`${Environtment.REACT_APP_API_URL}/partners`).then((res) => {
      if(res?.status === 200) { 
        setParners(res?.data?.data);
      }
    }).catch((err) => {
      console.log('err', err);
    })
  }
  useEffect(() => {
    getPartners();
  }, [])
  return {
    DataPartner,
    partners,
    Environtment,
  }
}

export default Services;