import PropTypes from 'prop-types';
import React from 'react';
const index = (props) => {
  const {classes, text, handler, icon, type, disabled, id} = props;
  
  return (
    <button 
      id={id || `${Math.random().toFixed(4)}`} 
      type={type || "submit"} 
      className={`${classes || 'flex w-full items-center pr-4 pl-3 py-2 hover:text-white hover:border-greenWebsite bg-greenWebsite hover:border-b-2 font-thin'}`} 
      onClick={(e)=> handler(e)} 
      disabled={disabled || false}
    >
      {icon && icon}
      {text && text}
    </button>
  )
}

index.propTypes = {
  classes: PropTypes.string,
  text: PropTypes.string,
  handler: PropTypes.func,
  icon: PropTypes.element,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
}

index.defaultProps = {
  classes: "",
  text: "",
  handler: ()=>{},
  icon: null,
  type: "",
  id: null,
}

export default index;