import { useRef } from "react";

const Services = () => {
  const ref = useRef(null);

  return {
    ref
  }
}

export default Services;