import React from "react";
import Services from "./services";
const index = () => {
  const {
    data,
    Environtment,
  } = Services();
  return (
    <section
        id="news"
        className="reveal flex flex-col justify-center items-center p-8"
      >
        <div className="py-4 mb-4 w-[20%] border-b-2 text-center border-red-500">
          <span className="text-[32px] font-light">News</span>
        </div>
        <div className="flex flex-wrap items-stretch">
          {data?.map((item) => (
            <div className="w-1/4 p-2">
              <div className="flex flex-col">
                <div className="w-full aspect-video">
                  <img src={`${Environtment.REACT_APP_SOURCE_URL}/${item?.image}`} alt="..."/>
                </div>
                <span>{item?.title}</span>
    
              </div>
            </div>
          ))}
        </div>
      </section>
  )
}

export default index;