import React from "react";
import PropTypes from 'prop-types';

const index = (props) => {
  const {
    label,
    classes,
    link,
  } = props;

  return (
    <a
      href={link}
      className={classes}
    >
      {label}
    </a>
  )
}

index.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.string,
  link: PropTypes.func,
}

export default index;