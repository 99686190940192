import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "components/molecules";
import { IoIosArrowUp } from "react-icons/io";

const Templates = (props) => {
  const { children, pathname } = props;

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    let btn = document.getElementById("to-top");
    if (offset < 100) {
      btn.classList.add("hidden");
    } else {
      btn.classList.remove("hidden");
    }
  }, [offset]);
  return (
    <React.Fragment>
      <main className="container-website">
        <Navbar classesLogo="navbar-logo" logo={"image/logo.png"} pathname={pathname}/>
        <div id="top" />
        {children}
        <Footer />
        <a
          id="to-top"
          href="/#top"
          className="p-2 m-8 transition delay-150 
    duration-300 ease-in-out bg-dark-blue rounded-sm border-black border text-white fixed bottom-0 right-0"
        >
          <IoIosArrowUp size={32} />
        </a>
      </main>
    </React.Fragment>
  );
};

export default Templates;
