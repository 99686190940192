import Services from "./services";
import parser from 'html-react-parser';
const index = () => {
  const {
    DataWhyPartner,
    data,
    Environtment
  } = Services();
  console.log('data', data);
  return (
    <section
        className="reveal flex flex-col justify-center items-center p-8"
      >
        <div className="py-4 mb-4 w-[20%] sm:w-[60%] border-b-2 text-center border-red-500">
          <span className="text-[32px] font-light">
            Why You Should Partner With Us
          </span>
        </div>
        <span className="my-4 text-center">
          We are always committed to maintaining the trust and quality of our
          products and services. So with this commitment, it can generate
          profits in the partnership. And we also create good value for our
          customers
        </span>
        <div className="flex flex-wrap w-full justify-center">
          {data?.map((item, index) => (
            <div
              key={index}
              className="w-1/3 sm:w-full p-4 flex flex-col items-center slide-in-content top"
            >
              <img
                src={`${Environtment.REACT_APP_SOURCE_URL}${item?.image}`}
                alt="..."
                className="w-[4rem] mb-3"
              />
              <span className="text-[1rem] font-bold mb-3">{item?.title}</span>
              <p className="text-center">{parser(item?.description)}</p>
            </div>
          ))}
        </div>
      </section>
  )
}

export default index;