import axios from "axios";
import { DataOurServices, Environtment } from "constant";
import { useEffect, useState } from "react";
const Services = () => {
  const [data, setData] = useState({
    paymentServices: [],
    billPayment: [],
    verificationId: [],
  });
  const [dataPaymentServices, setDataPaymentServices] = useState([]);
  const [dataBillPayment, setDataBillPayment] = useState([]);
  const [dataVerification, setDataVerification] = useState([]);

  const getDataPaymentServices = async () => {
    axios
      .get(`${Environtment.REACT_APP_API_URL}/payment-services`)
      .then((res) => {
        if (res?.status === 200) {
          setDataPaymentServices(res?.data?.data);
          // setData({ ...data, paymentServices: res?.data?.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getDataBillPayment = () => {
    axios
      .get(`${Environtment.REACT_APP_API_URL}/bill-payment`)
      .then((res) => {
        if (res?.status === 200) {
          setDataBillPayment(res?.data?.data);
          // setData({ ...data, billPayment: res?.data?.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getVerificationId = () => {
    axios
      .get(`${Environtment.REACT_APP_API_URL}/verification-id`)
      .then((res) => {
        setDataVerification(res?.data?.data);
        // setData({ ...data, verificationId: res?.data?.data });
      })
      .catch((err) => {});
  };

  useEffect(() => {
      getVerificationId();
      getDataBillPayment();
      getDataPaymentServices();
  }, []);

  return {
    data,
    DataOurServices,
    Environtment,
    dataPaymentServices,
    dataBillPayment,
    dataVerification,
  };
};

export default Services;
